import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto otp`}</strong>{` -- generate and verify one-time passwords`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto otp <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto otp`}</strong>{` command group implements TOTP and HOTP one-time passwords
(mention RFCs)`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Generate a new TOTP token and it's QR Code to scan:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto otp generate --issuer smallstep.com --account name@smallstep.com -qr smallstep.png > smallstep.totp

$ cat smallstep.totp
55RU6WTUISKKGEYVNSSI7H6FTJWJ4IPP
`}</code></pre>
    <p>{`Scan the QR Code using Google Authenticator, Authy or a similar software and
use it to verify the TOTP token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto otp verify --secret smallstep.totp
Enter Passcode: 614318
ok
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "generate/"
              }}>{`generate`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a one-time password`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "verify/"
              }}>{`verify`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a one-time password`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      